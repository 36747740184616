var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full min-contents" },
    [
      _c(
        "div",
        { staticClass: "container mx-auto my-4 mb-8" },
        [
          _c(
            "ValidationObserver",
            {
              ref: "observer",
              staticClass: "p-4 border boder-b-0 border-very-light-grey mb-4",
              attrs: { tag: "div" },
            },
            [
              _vm.featuredList
                ? _c("div", { staticClass: "grid grid-cols-12 gap-6" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-6" },
                      [
                        _c("fl-labeled-input", {
                          staticClass: "mb-4",
                          attrs: {
                            fieldId: "list_name",
                            label: "Name",
                            rules: { required: true },
                          },
                          model: {
                            value: _vm.featuredList.translations.ja.list_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.featuredList.translations.ja,
                                "list_name",
                                $$v
                              )
                            },
                            expression:
                              "featuredList.translations.ja.list_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-6" },
                      [
                        _c("fl-labeled-input", {
                          staticClass: "mb-4",
                          attrs: {
                            fieldId: "list_name",
                            label: "Name",
                            rules: { required: true },
                          },
                          model: {
                            value: _vm.featuredList.translations.en.list_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.featuredList.translations.en,
                                "list_name",
                                $$v
                              )
                            },
                            expression:
                              "featuredList.translations.en.list_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-12" },
                      [
                        _c("fl-translatable-textarea", {
                          attrs: {
                            fieldId: "description",
                            rules: { required: true, max: 1000 },
                            rules2: { required: true, max: 4000 },
                            label: "description",
                          },
                          model: {
                            value: _vm.featuredList.translations,
                            callback: function ($$v) {
                              _vm.$set(_vm.featuredList, "translations", $$v)
                            },
                            expression: "featuredList.translations",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-span-2" }, [
                      _c("span", [_vm._v("運用メモ")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("FlTextArea", {
                          attrs: { label: "message", rules: { max: 4000 } },
                          model: {
                            value: _vm.featuredList.management_note,
                            callback: function ($$v) {
                              _vm.$set(_vm.featuredList, "management_note", $$v)
                            },
                            expression: "featuredList.management_note",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "col-span-5 my-auto text-sm" }, [
                      _vm._v(" " + _vm._s(_vm.featuredListUrl(_vm.uuid)) + " "),
                    ]),
                    _c("span", { staticClass: "col-start-10 col-span-2" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-full h-full px-3 py-2 bg-light-yellow disabled:bg-light-grey disabled:cursor-not-allowed font-bold",
                          attrs: { disabled: _vm.copying },
                          on: {
                            click: function ($event) {
                              return _vm.copyList()
                            },
                          },
                        },
                        [_vm._v(" リストを複製する ")]
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "col-span-1" },
                      [
                        _c(
                          "LinkButton",
                          {
                            attrs: {
                              classList: "px-2 py-2 text-center w-full",
                            },
                            on: {
                              clickButton: function ($event) {
                                return _vm.updateList()
                              },
                            },
                          },
                          [_vm._v("save")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "p-4 border boder-b-0 border-very-light-grey mb-4 grid grid-cols-12 gap-4",
            },
            [
              _vm.featuredList && _vm.featuredList.movies.length
                ? _vm._l(_vm.featuredList.movies, function (movie, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "col-span-4" },
                      [
                        _c(
                          "FlMovieImage",
                          {
                            attrs: { value: movie.featured_images[0].file.url },
                          },
                          [
                            movie.recommend
                              ? _c("div", { staticClass: "ribbon_outer" }, [
                                  _c(
                                    "label",
                                    { staticClass: "ribbon bg-base-yellow" },
                                    [_vm._v("recommend")]
                                  ),
                                ])
                              : _vm._e(),
                            movie.visible_screener
                              ? _c(
                                  "div",
                                  { staticClass: "absolute bottom-2 right-2" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "h-6 w-6 text-white text-shadow sm:text-xxs",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 20 20",
                                          fill: "currentColor",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d: "M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm3 2h6v4H7V5zm8 8v2h1v-2h-1zm-2-2H7v4h6v-4zm2 0h1V9h-1v2zm1-4V5h-1v2h1zM5 5v2H4V5h1zm0 4H4v2h1V9zm-1 4h1v2H4v-2z",
                                            "clip-rule": "evenodd",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute text-left text-white font-bold leading-none left-0 bottom-0 pl-2 pb-2",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-lg pb-2 text-shadow sm:text-xs sm:pb-1",
                                  },
                                  [_vm._v(_vm._s(movie.film_name))]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-shadow sm:text-xxs" },
                                  [_vm._v(_vm._s(movie.director))]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "relative" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-xxs text-slate-500 absolute top-0 left-0",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.dateString(movie.created_at, false))
                              ),
                            ]
                          ),
                          movie.created_by
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "text-xxs text-slate-500 absolute top-0 right-0",
                                },
                                [_vm._v(_vm._s(movie.created_by.name))]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-6 grid grid-cols-3 gap-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "col-span-1 py-1 font-bold bg-light-yellow text-sm hover:bg-light-yellow-lighter focus:bg-light-yellow-darker",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleRecommend(movie)
                                  },
                                },
                              },
                              [
                                movie.recommend
                                  ? _c("span", [_vm._v("un-recommend")])
                                  : _c("span", [_vm._v("recommend")]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "col-span-1 py-1 font-bold bg-light-yellow text-sm hover:bg-light-yellow-lighter focus:bg-light-yellow-darker",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleScreener(movie)
                                  },
                                },
                              },
                              [
                                movie.visible_screener
                                  ? _c("span", [_vm._v("no screener")])
                                  : _c("span", [_vm._v("allow screener")]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "col-span-1 py-1 font-bold bg-light-yellow text-sm hover:bg-light-yellow-lighter focus:bg-light-yellow-darker",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFromList(movie)
                                  },
                                },
                              },
                              [_vm._v(" delete ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  })
                : [
                    _c(
                      "p",
                      { staticClass: "col-span-12 text-center text-sm" },
                      [_vm._v("empty list")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "copyNotice",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "copySuccessNotice",
          contents: "複製に成功しました。複製したリストを表示しますか？",
          buttons: ["キャンセル", "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.redirectCopiedList()
          },
        },
      }),
      _vm.deleteMovie
        ? _c("NoticeModal", {
            attrs: {
              modalName: "checkDelete",
              title: "おすすめリストの削除",
              contents:
                _vm.deleteMovie.film_name + "を削除します。よろしいですか？",
              buttons: ["キャンセル", "OK"],
            },
            on: {
              click: function ($event) {
                return _vm.updateMovie("trash", _vm.deleteMovie)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }